/**
 * component which genereates HTML-comments used to turn search index off
 * not really a styled component, therefore using inline styles to hide
 * the comments in react workaround from breaking anything.
 */

// move the objects in static variables so they do not trigger rerenders
const style = { display: 'none' };
const offHtml = { __html: '<!-- searchindex:off -->' };
const onHtml = { __html: '<!-- searchindex:on -->' };

export function SearchIndexOff({ children }: Readonly<React.PropsWithChildren>) {
  return (
    <>
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={offHtml}
        style={style}
      />
      {children}
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={onHtml}
        style={style}
      />
    </>
  );
}
